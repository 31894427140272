import React from 'react'
import {
  CFImage,
  CFLink,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { yelp, facebook, instagram, zomato } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView mv="20px" column justifyStart>
          <CFView h3 futura bold color="#C60000" mb="20px">
            SOCIAL MEDIA
          </CFView>
          <CFView row center black bold>
            {/* <CFLink href="https://www.facebook.com/Hee-Rae-Deung%ED%9D%AC%EB%9E%98%EB%93%B1-535103336558527/">
              <CFImage
                w="40px"
                pr="10px"
                src={facebook}
                alt="Hee Rae Deung Facebook"
              />
            </CFLink> */}
            <CFLink href="https://www.yelp.ca/biz/hee-rae-deung-coquitlam">
              <CFImage w="40px" pr="10px" src={yelp} alt="Hee Rae Deung Yelp" />
            </CFLink>
            <CFLink href="https://www.zomato.com/vancouver/hee-rae-deung-coquitlam-coquitlam">
              <CFImage
                w="40px"
                pr="10px"
                src={zomato}
                alt="Hee Rae Deung Zomato"
              />
            </CFLink>
            <CFLink href="https://www.instagram.com/heeraedeung/">
              <CFImage
                w="40px"
                pr="10px"
                src={instagram}
                alt="Hee Rae Deung Instagram"
              />
            </CFLink>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView mb="13px" mt="15px" column justifyStart>
          <CFView h3 futura bold color="#C60000" mb="15px">
            SOCIAL MEDIA
          </CFView>
          <CFView row justifyStart alignCenter black bold>
            {/* <CFLink href="https://www.facebook.com/Hee-Rae-Deung%ED%9D%AC%EB%9E%98%EB%93%B1-535103336558527/">
              <CFImage
                w="40px"
                pr="10px"
                src={facebook}
                alt="Hee Rae Deung Facebook"
                hover
              />
            </CFLink> */}
            <CFLink href="https://www.yelp.ca/biz/hee-rae-deung-coquitlam">
              <CFImage
                w="40px"
                pr="10px"
                src={yelp}
                alt="Hee Rae Deung Yelp"
                hover
              />
            </CFLink>
            <CFLink href="https://www.zomato.com/vancouver/hee-rae-deung-coquitlam-coquitlam">
              <CFImage
                w="40px"
                pr="10px"
                src={zomato}
                alt="Hee Rae Deung Zomato"
                hover
              />
            </CFLink>
            <CFLink href="https://www.instagram.com/heeraedeung/">
              <CFImage
                w="40px"
                pr="10px"
                src={instagram}
                alt="Hee Rae Deung Instagram"
                hover
              />
            </CFLink>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
